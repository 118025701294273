.signInModal{
  width: 679px !important;
  padding: 50px 10px !important;
}

.modalIcon{
  width: 20px;
  height: 20px;
}

@media(max-width: 700px){
  .signInModal{
    width: 84vw;
  }
}

@media(max-width: 480px){
  .modalIcon{
    width: 10px;
    height: 10px;
  }
}