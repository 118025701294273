.signUpForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* row-gap: 0.8rem; */
  font-family: "Poppins";
  padding: 20px 36px 30px 36px;
  height: fit-content;
}

.signUpForm h1 {
  color: #282828;
  font-family: Georama;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  /* 104.167% */
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 20px;
}

.profileContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.profileIcon {
  width: 57px;
  height: 57px;
}

.submitButton {
  width: auto;
  padding: 8px 14px;
  background: #F01E29;
  color: #FFF;
  font-family: Georama;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  /* 75% */
  text-transform: capitalize;
}

.cancelButton {
  width: auto;
  padding: 8px 14px;
  border-radius: 5px;
  background: #FFF;
  border: 1px solid #F01E29;
  color: #6A6A6A;
  font-family: Georama;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  /* 75% */
  text-transform: capitalize;
}

.inputField {
  /* margin-bottom: 28px; */
  position: relative;

}

.passwordField {
  margin-bottom: 25px;
}

.inputLabel {
  color: #282828;
  font-family: Georama;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 112.5% */
  text-transform: capitalize;
  position: relative;
  margin-bottom: 5px;
}

.inputLabel::after {
  content: "*";
  position: absolute;
  /* Position the pseudo-element absolutely */
  top: 50%;
  /* Adjust the top position */
  transform: translateY(-50%);
  /* Center vertically */
  width: 20px;
  height: 20px;
  color: #f01e29;
  padding-left: 5px;
  /* Other styles */
}

.inputLabelOptional {
  color: #282828;
  font-family: Georama;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 112.5% */
  text-transform: capitalize;
  position: relative;
  margin-bottom: 10px;
}

.errorMessage {
  font-size: 14px;
  color: red;
}

.inputField input {
  padding: 7px !important;
}

.passwordField input {
  padding: 7px !important;
}

.customArrow {
  width: 10px;
  height: 20px;
}

/* .signUpForm p input {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
} */

.signUpForm .inputField {
  display: flex;
  flex-direction: column;
  /* gap: 3px; */
  margin-top: 16px;
}

.recaptchaContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 14px;
  font-family: Georama;
  margin-bottom: 10px;
  font-weight: 500;
}

.checking {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-weight: 500;
}

.checkSuccess {
  display: flex;
  flex-direction: row;
  gap: 5px;
  color: darkgreen;
}

.checkFailed {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  color: #f01e29;
}

.tryAgain {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
  color: #6a6a6a;
}

.signUpButton {
  width: 121px;
  height: 40px;
  border-radius: 5px;
  background: #f01e29;
  padding: 10px 20px;
  color: #fff;
  font-family: Georama;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 140% */
  text-transform: capitalize;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.signUpForm .inputField label {
  font-size: 17px;
  font-weight: 500;
}

.signUpForm .passwordField {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-top: 16px;
}

.signUpForm .passwordField label {
  font-size: 17px;
  font-weight: 500;
}

.signUpOptions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.recoverPassword {
  color: #000;
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
}

.sumbitButton {
  height: 3.188rem;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 0.625rem;
}

.orText {
  width: 100% !important;
  text-align: center !important;
  justify-content: center;
}

.changeLoginType {
  border-radius: 0.375rem;
  border: none;
  padding: 0.563rem 1.25rem;
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
  background-color: #fff;
  border: 1px solid #f2ad00;
  color: #f2ad00;
  cursor: pointer;
}

.signUpForm .phoneInput {
  width: 100% !important;
  height: 3.375rem !important;
  font-size: 13px !important;
  height: 4.375rem !important;
}

.signupButton {
  height: 48px !important;
  margin-top: 24px;
}

.signUpFooter {
  display: flex;
  font-family: "Poppins";
  justify-content: center;
  cursor: pointer;
}

.signUpFooter p {
  color: #282828;
  text-align: right;
  font-family: Georama;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  /* 141.667% */
  text-decoration-line: underline;
}

.signUpLink {
  color: #ef1e29;
  font-family: Georama;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  text-decoration-line: underline;
}

.inputBottom {
  color: #6A6A6A;
  font-family: Georama;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 125% */
  margin-top: 0px;
}

.agreeContainer {
  display: flex;
  flex-direction: column;
  padding: 30px 26px;
}

.agreeHeading {
  color: #282828;
  font-family: Georama;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  /* 104.167% */
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 30px;
}

.contentDiv {
  width: 100%;
  height: 180px;
  border-radius: 4px;
  border: 1px solid #ACACAE;
  padding: 0px;
  overflow-y: scroll;
  margin-bottom: 24px;

  font-size: 16px;
  color: #616161;
  font-weight: 500;
  line-height: 23px;
}

.description ul,
.description ol {
  padding-left: 40px;
  /* Default for LTR */
  list-style-position: outside;
  margin: 10px 0 10px 0;
}

/* Specific styles for RTL direction */
.description:dir(rtl) ul,
.description:dir(rtl) ol {
  padding-left: 0;
  /* Reset left padding */
  padding-right: 40px;
  /* Add right padding for RTL */
}

.description ul li,
.description ol li {
  margin-left: 20px;
  /* Default for LTR */
  line-height: 1.5;
}

/* Specific styles for RTL direction */
.description:dir(rtl) ul li,
.description:dir(rtl) ol li {
  margin-left: 0;
  /* Reset left margin */
  margin-right: 20px;
  /* Add right margin for RTL */
}

.checkboxDiv {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 24px;
}

.checkBoxInput label:before {
  border: 1px solid #000;
}

.agreeMessage {
  color: #444;
  font-family: Georama;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.agreeMessage a {
  color: #1877F2;
  text-decoration-line: underline;
  text-decoration-style: solid;
}

.buttonsDiv {
  display: flex;
  align-items: center;
}

.acceptDeclineDiv {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: auto;
}

.acceptButton {
  display: flex;
  height: 44px;
  width: fit-content;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #EF1E29;

  color: #FFF;
  text-align: center;
  font-family: Georama;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.acceptButton:disabled {
  background: #ddd !important;
  color: #000;
}

.declineButton {
  display: flex;
  height: 44px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #EF1E29;
  background: #FFF;

  color: #EF1E29;
  text-align: center;
  font-family: Georama;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.declineMessage {
  color: #444;
  text-align: center;
  font-family: Georama;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  margin-bottom: 25px;
}

.okayButton {
  display: flex;
  height: 44px;
  width: fit-content;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #EF1E29;

  color: #FFF;
  text-align: center;
  font-family: Georama;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;

  margin-left: auto;
  margin-right: auto;
}

.okayButton:disabled {
  background: #ddd !important;
  color: #000;
}

.printButton {
  border-radius: 5px;
  background: #FFF;
  display: flex;
  height: 44px;
  padding: 0px 20px 0px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;

  color: #EF1E29;
  text-align: center;
  font-family: Georama;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.printIcon {
  width: 20px;
  height: 20px;
}

@media (max-width: 1024px) {
  .contentDiv {
    font-size: 14px;
    line-height: 20px;
  }
}


@media (max-width: 757px) {
  .submitButton {
    width: auto;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
  }

  .cancelButton {
    width: auto;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
  }

  .agreeContainer {
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
  }

  .agreeHeading {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .contentDiv {
    font-size: 12px;
    line-height: 18px;
  }

  .acceptButton {
    height: 38px;
    padding: 0px 18px;
    font-size: 14px;
    line-height: normal;
  }

  .declineButton {
    height: 38px;
    padding: 0px 18px;
    font-size: 14px;
    line-height: normal;
  }

  .declineMessage {
    font-size: 14px;
    line-height: normal;

    margin-bottom: 20px;
  }

  .okayButton {
    height: 38px;
    padding: 0px 18px;
    font-size: 14px;
    line-height: normal;
  }

  .printButton {
    font-size: 14px;
    line-height: normal;
  }
}

@media (max-width: 550px) {
  .signUpForm {
    padding: 0px;
  }

  .signUpForm h1 {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .profileIcon {
    width: 32px;
    height: 32px;
    margin-bottom: 20px;
  }

  .inputLabel {
    font-size: 10px;
  }

  .inputLabelOptional {
    font-size: 10px;
  }

  /* .signUpForm input {
    padding: 5px !important;
    font-size: 10px !important;
  } */

  .signUpForm .inputField {
    margin-bottom: 20px;
    margin-top: 0;
  }

  .inputBottom {
    font-size: 10px;
    margin-top: 2px;
  }

  .recaptchaContent {
    font-size: 12px;
  }

  .signUpButton {
    width: 60px;
    height: 18px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buttonContainer {
    margin-bottom: 10px;
  }

  .signUpFooter p {
    font-size: 12px;
  }

  .signUpLink {
    font-size: 12px;
  }


}

/* @media (min-width: 868px) {
  .signinForm .phoneInput {
    font-size: 16px !important;
  }
} */

/* .signupForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}

.sumbitButton {
  height: 3.188rem;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 0.625rem;
} */