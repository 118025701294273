.container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.humburger {
  width: 27px;
  height: 18px;
}

/* .bar1,
.bar2,
.bar3 {
  width: 2.188rem;
  height: 0.313rem;
  background-color: #333;
  margin: 0.375rem 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 0.688rem) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -0.688rem) rotate(45deg);
} */