.navbar {
  position: fixed;
  z-index: 1000;
  background-color: #ffffff;
  width: 100vw;
  padding: 20px 35px 20px 35px;
  height: 70px;
  top: 0;
  display: flex;
  justify-content: center;
  box-shadow: 0px 448px 125px 0px rgba(0, 0, 0, 0.00), 0px 287px 115px 0px rgba(0, 0, 0, 0.01), 0px 161px 97px 0px rgba(0, 0, 0, 0.03), 0px 72px 72px 0px rgba(0, 0, 0, 0.04), 0px 18px 39px 0px rgba(0, 0, 0, 0.05);
}

.navbar.dashboard {
  background-color: #162026;
}

.mainContent {
  margin-top: 70px;
  /* margin-top: 4.688rem; */
  width: 100%;
  /* padding-bottom: 5rem; */
}

.comingSoonContent {
  margin-top: 0px !important;
  height: 100vh;
}

.noScroll {
  overflow: hidden !important;
}

.footer {
  width: 100%;
  margin-top: auto;
}

/* @media (max-width: 1550px) {
  .navbar {
    height: 70px;
    padding: 20px 40px 20px 40px;
  }
} */

@media (max-width: 1360px) {
  .navbar {
    height: 70px;
    padding: 20px 20px 20px 20px;
  }
}

@media (max-width: 757px) {
  .navbar {
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
  }
}