.mainNavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  /* margin: auto; */
  width: 100vw;
  height: 100%;
  /* width: 90%; */
  font-family: 'Poppins';
}

.navbarGroup {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  gap: 1.75rem;
}

.navbarList {
  /* display: none; */
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 2rem;
}

.navbarList button {
  white-space: nowrap;
}

.humburgerButton {
  display: none;
  /* display: inline-block; */
}

.searchIcon {
  width: 2.375rem;
  height: 2.375rem;
  cursor: pointer;
  margin-right: 5px;
}

.searchOpen {
  display: flex;
  gap: 1.188rem;
}

.searchOpen .searchButton {
  display: flex;
  gap: 0.5rem;
  border-radius: 32px;
}

.searchOpen img {
  cursor: pointer;
}

.searchOutline {
  color: #EF1D2B;
  width: 25px;
  height: 25px;
}

@media (max-width: 1024px) {
  .navbarGroup {
    width: 100%;
    justify-content: space-between;
  }

  .burgerSearchIcons {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
  }

  .searchIcon {
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
  }

  .humburgerButton {
    display: inline-block;
  }
}

/* @media (min-width: 1020px) {
  .navbarList {
    display: flex;
  }

  .humburgerButton {
    display: none;
  }
} */