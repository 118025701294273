.navbarLogo {
  width: 138px;
  height: 38px;
  margin-top: auto;
}

.logoContainer {
  display: flex;
  align-items: center;
}

@media (max-width: 1360px) {
  .navbarLogo {
    width: 110px;
    height: 30px;
  }
}

@media (max-width: 1200px) {
  .navbarLogo {
    width: 83px;
    height: 25px;
  }
}