.consentModal {
    position: fixed;
    z-index: 1000001;
    color: #ffffff;
    /* color: #3d3d3d; */
    font-family: 'Georama', sans-serif;
}

.consentLarge {
    width: 100vw;
    /* width: 420px; */
    max-height: 70%;
    /* left: 20px;
    bottom: 20px; */
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    background-color: #CD0E18;
    /* background-color: #f01e29; */
    box-shadow: 0 0 18px rgba(0, 0, 0, .2);
    padding: 32px;
    border-radius: 8px;
}

.consentMob {
    width: 90vw;
    max-height: 60%;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    background-color: #CD0E18;
    /* background-color: #f01e29; */
    box-shadow: 0 0 18px rgba(0, 0, 0, .2);
    padding: 24px;
    border-radius: 6px;
    overflow-y: scroll;
}

.title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 10px;
}

.info {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 16px;
}

.info span {
    text-decoration: underline;
    cursor: pointer;
}

.buttonsContainer {
    margin-top: auto;
    /* width: 100%; */
    border-top: 1px solid #ebebeb;
    padding-top: 12px;
}

.buttonsDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    margin-left: auto !important;
}

.button {
    width: auto;
    padding: 12px 21px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Georama', sans-serif;
    background: #ebebeb;
}

.mlAuto {
    margin-left: auto !important;
}

@media (max-width: 350px) {
    .button {
        width: auto;
        padding: 10px 18px;
        border-radius: 20px;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        font-family: 'Georama', sans-serif;
        background: #ebebeb;
    }
}