.navbarListContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbarList {
  margin: 0;
  padding: 0;
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #3D3D3D;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700 !important;
}

.historicalData {
  text-transform: uppercase;
  margin-left: 19px;
  cursor: pointer;
}

.linkFlex {
  display: flex;
  align-items: center;
}

.blackArrow {
  width: 12px;
  height: 7.5px;
  margin-left: 6px;
}

.navbarList .pages {
  display: flex;
  gap: 16px;
}

.navbarList .links {
  flex-direction: row;
  row-gap: 1.5rem;
  /* display: flex;
  flex-direction: column;
  column-gap: 1.5rem; */
}

.navbarList .links .navIcon {
  margin-top: 2px;
}

.navbarList .links .iconLinks {
  display: flex;
}

.navbarList .links .iconLinks .ant-select-selector {
  border: none;
}

.navbarList .links .iconLinks img {
  height: 19px;
}

.navbarList .links .select {
  width: 8.4vw;
  height: 20px;
  border: none;
  font-size: 16px;
  background-color: none;
}

.navbarList .searchOptions {
  display: flex;
  gap: 1rem;
  margin-left: auto;
}

.group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.searchOutline {
  color: #EF1D2B;
  /* width: 25px; */
  height: 25px;
}

.loginButton {
  width: auto;
  padding: 8px 16px;
  margin-left: auto;
  border-radius: 5px;
  background: #F01E29;
  color: #FFF;
  font-family: Georama;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  text-transform: capitalize;
}

.donateButton {
  width: auto;
  height: 35px;
  padding: 7px 14px;
  margin-left: auto;
  border-radius: 5px;
  background: #F01E29;
  color: #FFF;
  font-family: Georama;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 5px;
}

.donateButton:hover {
  color: #fff !important;
}

.donateButton img {
  width: 15px;
  height: 15px;

}

.circularDiv {
  width: 44px !important;
  height: 44px;
  border: 2px solid #ACACAE;
  border-radius: 50%;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* overflow: hidden; */
}

.profilePic {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.profileDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.profileDiv p {
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  max-width: 12ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1565px) {
  .navbarList {
    font-size: 14px;
  }

  .donateButton {
    height: 29px;
    font-size: 13px;
  }
}

@media (max-width: 1360px) {
  .navbarList {
    font-size: 14px;
  }

  .navbarList .pages {
    display: flex;
    gap: 14px;
  }

  .group {
    gap: 15px;
  }
}

@media (max-width: 1360px) {
  .navbarList {
    font-size: 14px;
  }

  .navbarList .pages {
    display: flex;
    gap: 11px;
  }

  .group {
    gap: 12px;
  }
}

@media (max-width: 1200px) {
  .profileDiv p {
    font-size: 12px;
  }
}

@media (max-width: 1024px) {
  .donateButton {
    height: 26px;
    font-size: 12px;
  }
}

/* @media (min-width: 1020px) {
  .navbarList .links {
    flex-direction: row;
    row-gap: 1.5rem;
  }
} */