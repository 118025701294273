html,
body,
#root {
  height: 100%;
  scroll-padding-top: 100px;
  border-bottom: none !important;
}

#root {
  display: flex;
  flex-direction: column;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Georama", "Montserrat", sans-serif;
  color: #0d0a19;
  list-style: none;
  /* background-color: #f2f2f2; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  font-size: 16px;
}

@media (min-width: 640px) {
  :root {
    font-size: 16px;
  }
}

@media (min-width: 868px) {
  :root {
    font-size: 16px;
  }
}

/* @media (min-width: 1024px) {
  :root {
    font-size: 20px;
  }
} */

/* @media (min-width: 1440px) {
  :root {
    font-size: 16px;
  }
} */

/* @media (min-width: 1920px) {
  :root {
    font-size: 20px;
  }
}

@media (min-width: 2560px) {
  :root {
    font-size: 30px;
  }
} */

.w-100 {
  width: 100%;
}

/* react-image-gallery - making thumbnail image responsive */
.image-fit img {
  object-fit: scale-down !important;
}

/* primary color - pink - #FF6666 */
/* primary grat color - #D9D9D9 */

/* navbar language selection option style changes */
.navbar-select {
  width: 100px;
  height: 35px;
}

.navbar-select .ant-select-selector {
  border: none !important;
  background: #f01e29 !important;
  color: #ffffff !important;
  box-shadow: none !important;
  font-size: 20px !important;
  border-radius: 5px !important;
  /* font-family: Poppins !important; */
}

.navbar-select .ant-select-selector .ant-select-selection-item {
  font-size: 16px !important;
  font-weight: 700 !important;
  margin-bottom: 4px;
  color: #ffffff !important;
}

.navbar-select .ant-select-selection-placeholder {
  color: #ffffff !important;
}

.navbar-select .ant-select {
  color: #ffffff !important;
}

.navbar-select .ant-select-arrow {
  color: #ffffff !important;
}

.navbar-select-dropdown .ant-select-item-option {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.navbar-select-placeholder-icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1550px) {
  .navbar-select {
    width: 100px;
    height: 28px;
  }

  .navbar-select .ant-select-selector .ant-select-selection-item {
    font-size: 13px !important;
    margin-bottom: 4px;
  }

  .navbar-select-dropdown .ant-select-item-option {
    font-size: 13px !important;
  }
}

@media (max-width: 1024px) {
  .navbar-select {
    width: 100%;
    height: 26px;
  }

  .navbar-select .ant-select-selector {
    font-size: 12px !important;
  }

  .navbar-select .ant-select-selector .ant-select-selection-item {
    font-size: 12px !important;
    margin-top: 2px !important;
  }

  .navbar-select-dropdown .ant-select-item-option {
    font-size: 12px !important;
  }

  .navbar-select .ant-select-selector .ant-select-selection-item {
    font-size: 12px !important;
  }
}

/* input style change */
.ant-input {
  /* border: none !important;
  border-bottom: 2px solid red !important; */
  border-radius: 0 !important;
  box-shadow: none !important;
}

.ant-input:focus {
  border-color: none !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper {
  padding: 0 !important;
  border-radius: 0 !important;
  font-size: 16px !important;
}

.ant-input-affix-wrapper-focused {
  border-color: none !important;
  box-shadow: none !important;
}

.ant-input-outlined {
  border: none !important;
  border-bottom: 1px solid #ef1e29 !important;
}

.navbar-input {
  width: 150px !important;
  margin-left: 5px;
}

.navbar-input .ant-input::placeholder {
  font-size: 14px;
  color: #828282;
  font-family: Georama;
  font-style: normal;
  font-weight: 500;
  /* line-height: 20px; */
}

.navbar-input .ant-input {
  font-size: 14px;
  color: #828282;
  font-family: Georama;
  font-style: normal;
  font-weight: 500;
  /* line-height: 20px; */
}

@media (max-width: 1550px) {
  .ant-input-affix-wrapper {
    font-size: 12px !important;
  }
}

@media (max-width: 1295px) {
  .navbar-input {
    width: 130px !important;
  }

  .navbar-input .ant-input::placeholder {
    font-size: 12px;
    color: #828282;
    font-family: Georama;
    font-style: normal;
    font-weight: 500;
    /* line-height: 20px; */
  }
}

@media (max-width: 1147px) {
  .navbar-input {
    width: 100px !important;
  }

}

@media (max-width: 1090px) {
  .navbar-input {
    width: 55px !important;
    margin-left: 10px;
  }

}

@media (min-width: 1024px) {
  .navbar-select {
    /* width: 8.4vw; */
  }
}

@media (min-width: 1920px) {
  .navbar-select .ant-select-selector {
    font-size: 18px !important;
  }
}

/* .slick-slide>div {
  margin: 0 10px;
} */

.custom-rate .ant-rate-star {
  font-size: 32px;
  /* Adjust the size as needed */
}

.slick-dots li button:before {
  color: #7e7e7e !important;
  /* Color for non-selected dots */
  font-size: 15px !important;
}

.slick-dots li.slick-active button:before {
  color: #f01e29 !important;
  /* Color for selected dot */
  font-size: 15px !important;
}

.slick-dots {
  margin-top: 50px !important;
  position: static !important;
}

.slick-dots {
  margin-top: 20px !important;
  position: static !important;
}

.break-news .slick-dots {
  margin-top: 30px !important;
  position: static !important;
  padding-top: 19px;
  border-top: 3px solid #f1f1f1;
  margin-bottom: 60px;
}

.article-slider .slick-dots {
  margin-top: 20px !important;
  position: static !important;
  padding-top: 20px;
  border-top: 3px solid #f1f1f1;
}

.slick-dots li {
  margin: 0 0 !important;
}

.article-slider .slick-slide {
  padding-right: 15px;
  /* Right padding for all slides */
  padding-left: 15px;
  /* Left padding for all slides */
}

@media (max-width: 1200px) {
  .slick-dots {
    margin-top: 20px !important;
    position: static !important;
  }

  .slick-dots li {
    margin: 0 0 !important;
  }

  .slick-dots li button:before {
    font-size: 12px !important;
  }

  .slick-dots li.slick-active button:before {
    font-size: 12px !important;
  }

  .video-outer .slick-dots {
    margin-top: 40px !important;
    position: static !important;
  }
}

@media (max-width: 970px) {}

@media (max-width: 480px) {
  .slick-dots li button:before {
    font-size: 10px !important;
  }

  .slick-dots li.slick-active button:before {
    font-size: 10px !important;
  }

  .break-news .slick-dots li button:before {
    font-size: 10px !important;
  }

  .break-news .slick-dots li.slick-active button:before {
    font-size: 10px !important;
  }

  .article-slider .slick-dots li button:before {
    font-size: 10px !important;
  }

  .article-slider .slick-dots li.slick-active button:before {
    font-size: 10px !important;
  }

  .article-slider .slick-dots {
    margin-top: 10px !important;
  }
}

.signin-modal .ant-modal .ant-modal-content {
  padding: 50px 10px !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  /* Semi-transparent background */
  backdrop-filter: blur(10px);
  /* Apply blur effect to background */
  z-index: 1000;
  /* Ensure the overlay is above other content */
}

.overlay-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  /* Semi-transparent background */
  backdrop-filter: blur(10px);
  /* Apply blur effect to background */
  z-index: 1000;
  /* Ensure the overlay is above other content */
}

.register-select .ant-select-selector {
  /* width: 100%; */
  height: 100% !important;
  padding: 19px 19px !important;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #ef1e29 !important;
  font-size: 16px !important;
  outline: none !important;
}

.register-select .ant-select-single {
  height: 100% !important;
}

.register-select .ant-select-selection-search {
  width: 100% !important;
  left: 0 !important;
}

.signInInput {
  font-size: 16px;
}

@media (max-width: 550px) {
  .register-select .ant-select-selector {
    padding: 5px !important;
    font-size: 10px !important;
  }
}

@media (max-width: 480px) {

  .CheckboxInput_wrapper__fOTuk input:checked+label,
  .CheckboxInput_wrapper__fOTuk input:not(:checked)+label {
    font-size: 10px !important;
    font-weight: 400;
    color: #344054;
    cursor: pointer;
    padding-left: 1.5rem !important;
  }

  .CheckboxInput_wrapper__fOTuk input:not(:checked)+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 14px !important;
    height: 14px !important;
    border: 1px solid #ddd;
    border-radius: 6px;
    background: #fff;
  }

  .register-select .ant-select-selector {
    padding: 5px 5px !important;
  }

  .register-select {
    font-size: 10px !important;
  }
}

.ant-spin .ant-spin-dot {
  font-size: 40px !important;
}

.ant-spin {
  display: flex !important;
  justify-content: center !important;
  margin-top: 80px !important;
}

/* pagination */

.custom-pagination-wedding {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 50px;
}

.custom-pagination-wedding ul {
  display: flex;
  gap: 28px;
  justify-content: center;
  margin-top: 60px;
}

.custom-pagination-wedding .pagination li.active a {
  background-color: #ff6666;
  /* Change this to your desired color */
  color: #fff;
  /* Change this to your desired text color */
  width: 30px;
  height: 44px;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  padding: 10px;
  border-radius: 8px;
}

.custom-pagination-wedding .pagination li a {
  color: black;
  width: 30px;
  height: 44px;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  padding: 10px;
}

.pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
  display: none !important;
}

.custom-pagination-wedding li:first-child {
  /* display: none; */
  font-size: 20px;
}

.custom-pagination-wedding li:last-child {
  /* display: none; */
  font-size: 20px;
}

.custom-pagination-wedding li:nth-child(2),
.custom-pagination-wedding li:nth-last-child(2) {
  display: none;
}

.prev-button {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  color: #ff6666;
  margin-right: 18px;
  cursor: pointer;
  display: none;
}

.next-button {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  color: #ff6666;
  margin-left: 18px;
  cursor: pointer;
  display: none;
}

@media (max-width: 360px) {
  .prev-button {
    margin-right: 8px;
  }

  .next-button {
    margin-left: 8px;
  }
}

.pagination {
  display: flex !important;
  gap: 7px;
  background: #fff;
  padding: 10px;
  align-items: center;
  border-radius: 3px;
}

.react-paginate {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  /* background: #fff; */
}

.pagination li a {
  color: #f01e29;
  text-align: center;
  font-family: Georama;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  padding: 5px;
  cursor: pointer;
}

.pagination li.active a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 30px;
  border-radius: 2px;
  background: #f01e29;
  color: #fff;
  text-align: center;
}

.previous-arrow,
.next-arrow {
  margin-top: 5px;
}

@media(max-width: 1024px) {
  .react-paginate {
    margin-top: 30px;
  }
}

@media (max-width: 1024px) {
  .pagination li a {
    font-size: 16px;
  }

  .pagination li.active a {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .pagination li a {
    font-size: 12px;
  }

  .pagination li.active a {
    font-size: 12px;
  }

  .previous-arrow,
  .next-arrow {
    width: 20px;
    height: 20px;
  }

  .pagination {
    padding: 2px 5px;
  }

  .pagination li.active a {
    width: 26px;
    height: 22px;
  }

  .react-paginate {
    margin-top: 10px;
  }
}

/* .contentModal .ant-modal {
  position: absolute;
} */

.custom-mask {
  background-color: transparent !important;
}

.account-modal .ant-modal-content {
  padding: 30px !important;
  /* Add padding to the modal content */
}

@media (max-width: 1024px) {
  .account-modal .ant-modal-content {
    padding: 20px !important;
    /* Add padding to the modal content */
  }
}

/* To show mandatory asterick sign after words in antd */
.ant-form-item-required::before {
  content: '' !important;
  display: none;
}

.ant-form-item-required::after {
  content: ' *' !important;
  color: #F01E29;
  visibility: visible !important;
}

/* Connect with us page - form style for antd inputs */
.connectForm .ant-form-item-required::before {
  color: #F01E29;
}

.connectForm .ant-form-item-required::after {
  color: #F01E29;
}

.connectForm .ant-form-item-label>label {
  font-size: 16px;
  color: #282828;
  font-family: Georama;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
}

.connectForm .ant-input::placeholder {
  font-size: 14px;
  color: #6A6A6A;
  font-family: Georama;
  font-style: normal;
  font-weight: 500;
  /* line-height: 20px; */
}

.connectForm .ant-input {
  font-size: 14px;
  color: #6A6A6A;
  font-family: Georama;
  font-style: normal;
  font-weight: 500;
  /* line-height: 20px; */
}

.connectForm .ant-input::placeholder {
  color: #969696;
}

.connectForm .ant-select-selection-placeholder {
  color: #969696;
}

.ant-input::placeholder {
  color: #969696;
}

.connectForm .ant-form-item-explain-error {
  font-size: 14px !important;
}

@media (max-width: 1200px) {
  .connectForm .ant-form-item-label>label {
    font-size: 14px;
    line-height: 13px;
  }

  .connectForm .ant-input::placeholder {
    font-size: 12px;
  }

  .connectForm .ant-input {
    font-size: 12px;
  }

  .connectForm .ant-input {
    font-size: 12px;
  }

  .connectForm .ant-form-item-explain-error {
    font-size: 12px !important;
  }
}

@media (max-width: 757px) {
  .connectForm .ant-form-item-label>label {
    font-size: 12px;
    line-height: 13px;
  }

  .connectForm .ant-input::placeholder {
    font-size: 10px;
  }

  .connectForm .ant-input {
    font-size: 10px;
  }

  .connectForm .ant-form-item-explain-error {
    font-size: 10px !important;
  }
}

/* antd select option - style change for project */
.ant-select-selector {
  border: none !important;
  /* Remove all borders */
  border-bottom: 1px solid red !important;
  /* Add red bottom border */
  box-shadow: none !important;
  /* Remove any shadow */
  outline: none !important;
  /* Remove outline */
  border-radius: 0 !important;
  font-family: Georama !important;
}

.ant-select-selector:focus,
.ant-select-selector-focused,
.ant-select-selector:hover {
  border: none !important;
  /* Remove all borders on focus/hover */
  border-bottom: 1px solid #ef1e29 !important;
  /* Add red bottom border on focus/hover */
  box-shadow: none !important;
  /* Remove any shadow */
  outline: none !important;
  /* Remove outline */
}

.ant-select-selection-item {
  margin-top: 4px !important;
  font-size: 14px;
  font-weight: 500 !important;
}

.ant-select-arrow {
  color: #6A6A6A !important;
}

.ant-select-dropdown {
  font-family: Georama, sans-serif;
}

.ant-select-item {
  font-size: 14px !important;
}

.ant-select-selection-placeholder {
  font-size: 14px;
  /* color: #969696; */
  color: #6A6A6A;
  font-family: Georama;
  font-style: normal;
  font-weight: 500;
  /* Optional: change placeholder color */
}

@media (max-width: 1200px) {
  .ant-select-selection-item {
    font-size: 12px;
  }

  .ant-select-selection-placeholder {
    font-size: 12px;
  }

  .ant-select-item {
    font-size: 12px !important;
  }

}

@media (max-width: 757px) {
  .ant-select-selection-item {
    font-size: 10px;
  }

  .ant-select-selection-placeholder {
    font-size: 10px;
  }

  .ant-select-item {
    font-size: 10px !important;
  }

}

/* for select with  no border radius */
.select-no-border-radius .ant-select-selector {
  border-radius: 0 !important;
}

/* tags select input in Add Article page in My Account */
.tags-select .ant-select-selector {
  border: none !important;
  /* Remove all borders */
  border-bottom: 1px solid #ef1e29 !important;
  /* Add red bottom border */
  box-shadow: none !important;
  /* Remove any shadow */
  outline: none !important;
  /* Remove outline */
  border-radius: 0 !important;
  font-family: Georama !important;
}

.tags-select .ant-select-selector:focus,
.tags-select .ant-select-selector-focused,
.tags-select .ant-select-selector:hover {
  border: none !important;
  /* Remove all borders on focus/hover */
  border-bottom: 1px solid #ef1e29 !important;
  /* Add red bottom border on focus/hover */
  box-shadow: none !important;
  /* Remove any shadow */
  outline: none !important;
  /* Remove outline */
}

.tags-select .ant-select-arrow {
  /* display: none !important; */
  /* Hide the dropdown arrow */
}

.tags-select .ant-select-selection-item {
  margin-top: 4px !important;
  background-color: #ef1e29 !important;
  color: #fff;
  font-size: 14px;
}

.tags-select .ant-select-selection-placeholder {
  font-size: 14px;
  color: #969696;
  /* color: #6A6A6A; */
  font-family: Georama;
  font-style: normal;
  font-weight: 500;
  /* Optional: change placeholder color */
}

.tags-select .ant-select-selection-item-remove {
  color: #fff !important;
}

@media (max-width: 1200px) {
  .tags-select .ant-select-selection-item {
    font-size: 12px;
  }

  .tags-select .ant-select-selection-placeholder {
    font-size: 12px;
  }
}

@media (max-width: 757px) {
  .tags-select .ant-select-selection-item {
    font-size: 10px;
  }

  .tags-select .ant-select-selection-placeholder {
    font-size: 10px;
  }
}

.ant-notification {
  font-family: 'Georama', sans-serif !important;
}

.search-fields .ant-input-outlined {
  border: none !important;
  border-bottom: none !important;
}

.search-fields {
  display: flex;
  gap: 15px;
}


.advanced-modal {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

@media(max-width: 48px) {

  .ant-form-item .ant-form-item-label>label {
    font-size: 12px !important;
  }
}

.video-wrapper {
  position: relative;
}

.video-wrapper .react-player__preview {
  pointer-events: none !important;
  /* Disable pointer events on the video */
}

.video-wrapper .react-player__shadow {
  pointer-events: none !important;
  /* Disable pointer events on the shadow */
}

.date-flex .ant-picker {
  border: none !important;
  cursor: pointer;
}

.date-flex .ant-picker .ant-picker-suffix {
  display: none !important;
}

.date-flex .ant-form {
  display: flex !important;
  gap: 15px !important;
}

.date-flex .ant-form-item .ant-form-item-label>label {
  font-size: 16px !important;
}

/* .ant-picker-focused {
  box-shadow: none;
} */



.react-tel-input .form-control {
  width: 100% !important;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 1px solid #ef1e29 !important;
  font-family: 'Georama', sans-serif;
  font-size: 14px !important;
  padding: 4px 11px 4px 58px !important;
}

.react-tel-input .form-control:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #ef1e29 !important;
}

.react-tel-input .form-control::placeholder {
  font-family: 'Georama', sans-serif;
  font-size: 14px !important;
  font-weight: 500 !important;
}

@media (max-width: 1200px) {
  .react-tel-input .form-control {
    font-size: 12px !important;
    padding: 4px 11px 4px 58px !important;
  }

  .react-tel-input .form-control::placeholder {
    font-size: 12px !important;
  }
}

@media (max-width: 757px) {
  .react-tel-input .form-control {
    font-size: 10px !important;
    padding: 4px 11px 4px 58px !important;
  }

  .react-tel-input .form-control::placeholder {
    font-size: 10px !important;
  }
}

.register-phone-input .react-tel-input .form-control {
  width: 100% !important;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 1px solid #ef1e29 !important;
  font-family: 'Georama', sans-serif;
  font-size: 14px !important;
  padding: 7px 11px 7px 58px !important;
}

@media (max-width:767px) {
  .register-phone-input .react-tel-input .form-control {
    font-size: 12px !important;
    padding: 7px 11px 7px 58px !important;
  }
}

.align-center {
  margin-right: auto;
  margin-left: auto;
}

.custom-register-form-class .ant-form-item {
  margin-bottom: 3px !important;
}

.ant-spin-lg .ant-spin-dot i {
  width: 20px !important;
  height: 20px !important;
}

.home-outer {
  flex-grow: 1;
  min-height: calc(100vh - 470px) !important;
}

.home-top {
  padding-top: 20px;
}

.no-data-home {
  display: flex;
  justify-content: center;
  margin-top: 120px;
}

@media(max-width: 550px) {
  .no-data-home {
    font-size: 16px;
  }
}

.sharethis-inline-share-buttons {
  z-index: 1 !important;
}

#st-1 {
  font-family: "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif;
  direction: ltr;
  display: block;
  opacity: 1;
  text-align: center;
  z-index: 1 !important;
}

/* hide recaptcha globally - to show badge in login/signup look code in Layout */
.grecaptcha-badge {
  visibility: hidden;
}

.ant-select-dropdown .ant-select-item-option {

  border-bottom: 2px solid white !important;
}

.ant-dropdown {
  z-index: 1200 !important;
}

.ant-dropdown-menu-submenu-popup {
  z-index: 1201 !important;
}

.navbar-menu .ant-dropdown-menu {
  padding: 4px;
}

.navbar-menu .ant-dropdown-menu-item {
  font-family: 'Georama', sans-serif;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.navbar-menu .ant-dropdown-menu-submenu-title, .ant-dropdown-menu-submenu {
  font-family: 'Georama', sans-serif;
  font-weight: 500 !important;
}

.navbar-menu .ant-dropdown-menu-submenu-arrow {
  margin-top: -23px;
}

@media (min-width:1024px) {
  .navbar-menu .ant-dropdown-menu {
    padding: 8px;
  }

  .navbar-menu .ant-dropdown-menu-item {
    font-size: 14px !important;
    padding: 8px 12px !important;
  }
}

/* Hide the entire toolbar in the image preview */
.ant-image-preview-operations {
  display: none !important;
}

.ant-image-preview-progress {
  display: none !important;
  /* position: absolute;
  top: 10px;
  right: 10px;
  bottom: auto; */
}

.ant-image-img {
  display: none;
}

.ant-image-preview-img {
  max-height: 90vh !important;
  object-fit: contain !important;
}

.fitContent {
  width: fit-content !important;
}

.ant-popover-inner-content {
  font-family: 'Georama', sans-serif;
  font-size: 14px;
  font-weight: 600 !important;
  color: #3a3a3a !important;
}

.ant-popover-inner-content .ant-popconfirm-title {
  font-weight: 600 !important;
}

.ant-popover-inner-content button {
  font-weight: 600 !important;
}

.ant-table {
  font-family: 'Georama', sans-serif !important;
}

.ant-table-thead>tr>th {
  font-family: 'Georama', sans-serif !important;
}

.ant-table-tbody>tr>td {
  font-family: 'Georama', sans-serif !important;
}

.ellipsis-dropdown {
  cursor: pointer;
  /* font-size: 21px; */
  /* -webkit-transform: rotate(90deg); */
  /* transform: rotate(90deg); */
  display: inline-block;
  line-height: 1;
  padding: 5px;
  border-radius: 50%;
  transition: background-color .3s ease
}

.ellipsis-dropdown:focus, .ellipsis-dropdown:hover {
  background-color: rgba(0, 0, 0, .05)
}

.comment-table .ant-spin .ant-spin-dot {
  font-size: 30px !important;
}

.comment-table .ant-spin {
  display: flex !important;
  justify-content: center !important;
  margin-top: auto !important;
}

.article-form-card {
  margin-bottom: 15px;
  font-family: 'Georama', sans-serif !important;
}

.title-tag {
  display: flex;
  align-items: center;
  gap: 10px;
}

:root {
  /* plyr-react player for videos */
  --plyr-color-main: #f01e29;
}

.plyr {
  border-radius: 15px;
  overflow: hidden;
  max-height: 883px;
  width: 100%;
}

.custom-editor-container {
  width: 100%;
  display: flex;
  align-items: center;
  border: none !important;
}

.custom-editor-container .mce-content-body {
  width: 100%;
  font-family: Georama, sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  padding: 4px 11px;
  border: none !important;
  border-bottom: 1px solid #ef1e29 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.custom-editor-container .mce-edit-focus,
.mce-content-body:focus,
.tox-tinymce-inline {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #ef1e29 !important;
  outline: none !important;
}

.custom-editor-container .mce-content-body::placeholder {
  color: #000 !important;
  padding-left: 11px !important;
}

.custom-editor-container .mce-content-body::before {
  color: #969696 !important;
  padding-left: 11px !important;
}

.custom-editor-container .mce-content-readonly {
  background-color: #f5f5f5 !important;
  /* Optional for disabled state */
}

@media (max-width: 757px) {
  .custom-editor-container .mce-content-body {
    width: 100%;
    font-family: Georama, sans-serif;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.85);
    padding: 4px 11px;
    border: none !important;
    border-bottom: 1px solid #ef1e29 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }

}

/* .agreementContent .MsoNormal, .MsoListParagraphCxSpFirst, .MsoListParagraphCxSpLast, .MsoListParagraphCxSpMiddle {
  margin: 5px 28px !important;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .agreementContent .MsoNormal, .MsoListParagraphCxSpFirst, .MsoListParagraphCxSpLast, .MsoListParagraphCxSpMiddle {
    margin: 5px 10px !important;
    font-size: 14px;
    text-indent: 0 !important;
  }
}

@media (max-width: 480px) {
  .agreementContent .MsoNormal, .MsoListParagraphCxSpFirst, .MsoListParagraphCxSpLast, .MsoListParagraphCxSpMiddle {
    margin: 5px 5px !important;
    font-size: 13px;
  }
} */

.heading-print {
  display: none !important;
}

.page-break-before {
  page-break-before: always;
  margin-top: 0;
}

.avoid-page-break {
  page-break-inside: avoid;
}

.page-break-after {
  page-break-after: always;
}

.agreementContent {
  page-break-inside: avoid;
  /* Prevent breaking inside elements */
  break-inside: avoid;
  /* Modern browsers */
  word-break: break-word;
  /* Ensure words wrap properly */
  overflow-wrap: break-word;
  white-space: normal;
}

.agreementContent * {
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}

p, h1, h2, h3, h4, h5, h6 {
  orphans: 4;
  /* Minimum lines before page break */
  widows: 4;
  /* Minimum lines after page break */
  page-break-after: avoid;
  page-break-inside: avoid;
}

h2, h3, h4 {
  page-break-before: always;
}

table, pre, blockquote, ul, ol {
  page-break-before: auto;
  page-break-after: avoid;
  page-break-inside: avoid;
}

@media print {
  .heading-print {
    display: block !important;
  }
}

.flag-modal .ant-modal-content {
  padding: 50px !important;
}

@media (max-width:757px) {
  .flag-modal .ant-modal-content {
    padding: 25px !important;
  }
}