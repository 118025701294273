.wrapper {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.wrapper input {
  accent-color: #EF1E29;
  cursor: pointer;
}

.wrapper input:checked+label,
.wrapper input:not(:checked)+label {
  font-size: 12px;
  font-weight: 400;
  color: #344054;
  cursor: pointer;
}

.wrapper input:checked+label {
  font-weight: 400;
  /* color: #344054; */
  color: #0d0a19;
}

.wrapper input:checked,
.wrapper input:not(:checked) {
  display: none;
}

.wrapper input:checked+label,
.wrapper input:not(:checked)+label {
  position: relative;
  padding-left: 1.75rem;
}


.wrapper input:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border: 1px solid #EF1E29;
  border-radius: 6px;
  background: #fff;
}

.wrapper input:checked+label:before {
  content: '';
  width: 16px;
  height: 16px;
  background: #EF1E29;
  border: 1px solid #EF1E29;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  border-radius: 6px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.wrapper input:checked+label:after,
.wrapper input:not(:checked)+label:after {
  content: '';
  width: 4px;
  height: 8px;
  border: 2.8px solid #fff;
  border-top: 0;
  border-left: 0;
  /* transform: rotate(45deg); */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  margin-top: -6.5px;
  margin-left: 5.5px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.wrapper input:not(:checked)+label:after {
  /* opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); */
  opacity: 0;
  -webkit-transform: rotate(36deg) scale(0);
  transform: rotate(36deg) scale(0);
}

.wrapper input:checked+label:after {
  /* opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); */
  opacity: 1;
  -webkit-transform: rotate(36deg) scale(1);
  transform: rotate(36deg) scale(1);
}


.wrapper.circle input:checked,
.wrapper.circle input:not(:checked) {
  display: none;
}

.wrapper.circle input:checked+label,
.wrapper.circle input:not(:checked)+label {
  position: relative;
  padding-left: 1.75rem;
}

.wrapper.circle input:checked+label:before,
.wrapper.circle input:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.125rem;
  height: 1.125rem;
  border: 1px solid #EF1E29;
  border-radius: 100%;
  background: #fff;
}

.wrapper.circle input:checked+label:after,
.wrapper.circle input:not(:checked)+label:after {
  content: '';
  width: 0.75rem;
  height: 0.75rem;
  background: #EF1E29;
  position: absolute;
  top: 0.24rem;
  left: 0.25rem;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.wrapper.circle input:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.wrapper.circle input:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media (min-width: 868px) {

  .wrapper input:checked+label,
  .wrapper input:not(:checked)+label {
    font-size: 24px;
  }
}