.navbarItem {
  /* display: inline-block; */
  white-space: nowrap;
  display: flex;
  text-transform: uppercase;
  /* justify-content: center; */
}

.navbarItem a {
  color: #3D3D3D;
  /* to make icons along with text align horizontally */
  display: flex;
  /* flex-direction: row; */
  /* justify-content: flex-start; */
  font-size: 12px;
  font-weight: 700;
  gap: 0.625rem;
  width: 100%;
  padding: 5px 10px;
  border-radius: 3px;
}

.navbarItem a:hover {
  /* color: #20183d; */
  text-decoration: none;
}

.navbarItem a.active {
  /* text-decoration: underline;
  text-underline-offset: 0.5rem; */
  /* font-weight: 900; */
  background-color: #F01E29;
  color: #fff;
}

@media (max-width: 1200px) {
  .navbarItem {
    text-transform: capitalize;
  }
}

@media (min-width:1024px) {
  .navbarItem {
    justify-content: center;
  }
}