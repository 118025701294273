.navbarItem {
  /* display: inline-block; */
  white-space: nowrap;
  display: flex;
  text-transform: uppercase;
  /* justify-content: center; */
}

.navbarItem a {
  color: #3D3D3D;
  /* to make icons along with text align horizontally */
  display: flex;
  /* flex-direction: row; */
  /* justify-content: flex-start; */
  gap: 0.625rem;
}

.navbarItem a:hover {
  /* color: #20183d; */
  text-decoration: none;
}

.navbarItem a.active {
  /* text-decoration: underline;
  text-underline-offset: 0.5rem; */
  /* font-weight: 900; */
  color: #3D3D3D;
}

.dropdownMenu {
  /* padding: 10px; */
  cursor: pointer !important
}

.dropDownitems {
  cursor: pointer;
}

@media (max-width: 1270px) {
  .navbarItem {
    text-transform: capitalize;
  }
}

@media (min-width:1024px) {
  .navbarItem {
    justify-content: center;
  }

  .dropdownMenu {
    margin-top: 10px;
  }
}