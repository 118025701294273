.sideNavbar {
  position: fixed;
  background-color: #fff;
  height: 100vh;
  /* width: 44vw; */
  min-width: 200px;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 21px;
  /* right: 0; */
  box-shadow: 0px 221px 62px 0px rgba(0, 0, 0, 0.00), 0px 141px 57px 0px rgba(0, 0, 0, 0.01), 0px 80px 48px 0px rgba(0, 0, 0, 0.03), 0px 35px 35px 0px rgba(0, 0, 0, 0.04), 0px 9px 19px 0px rgba(0, 0, 0, 0.05);
}

.right {
  right: 0;
}

.left {
  left: 0;
}

/* .sideNavbar .searchInput {
  width: 90%;
}

.sideNavbar .searchInput input {
  background-color: #f3f3f3;
  border: none;
  height: 40px;
  width: 100%;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.sideNavbar .navbarList {
  flex-direction: column !important;
  width: 85%;
  z-index: 60;
  font-size: 16px;
  font-weight: 600;
  row-gap: 12px;
} */