.footer {
  width: 100%;
  height: 400px;
  /* margin-top: 10rem; */
  background-color: #262626;
  display: flex;
}

.footerContent {
  padding: 40px 40px 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.footerDiv {
  border-radius: 10px;
  background: #333;
  height: 370px;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.footerDiv img {
  width: 158px;
  height: 42px;
  cursor: pointer;
}

.footerPages {
  border-radius: 10px;
  background: #333;
  /* height: 312px; */
  height: 370px;
  padding: 20px;
  width: 25%;
}

.pagesTitle {
  padding: 10px;
  width: 19vw;
  border-radius: 10px;
  background: #262626;
  margin-bottom: 22px;
  color: #fff;
  font-family: Georama;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 128.571% */
  text-transform: capitalize;
}

.pagesList {
  color: #fff;
  font-family: Georama;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  /* 108.333% */
  margin-bottom: 20px;
  padding-left: 10px;
  cursor: pointer;
}

.subMenuItems {
  padding-left: 10px;
  color: #fff;
  font-size: 12px;
  font-family: Georama;
  font-weight: 400;
  cursor: pointer;
}

.subMenuFlex {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 20px;
  list-style-type: disc;
  list-style-position: inside;
}

.subMenuList {
  color: #fff;
  font-family: Georama;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  /* 108.333% */
  margin-bottom: 10px;
  padding-left: 10px;
  cursor: pointer;
}

.footerPolicy {
  border-radius: 10px;
  background: #333;
  height: 370px;
  width: 25%;
  padding: 20px;
}

.footerPolicy p {
  color: #fff;
  font-family: Georama;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 66.667% */
  text-decoration-line: underline;
  margin-bottom: 12px;
  cursor: pointer;
}

.footerConnect {
  border-radius: 10px;
  background: #262626;
  height: 370px;
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.truth {
  color: #fff;
  margin-top: 15px;
  text-align: center;
  font-family: Georama;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  /* 100% */
  width: 19vw;
}

.connectWithUs {
  border-radius: 10px;
  background: #333;
  padding: 20px;
  height: 50%;
}

.connectWithUs p {
  border-radius: 10px;
  background: #262626;
  color: #fff;
  font-family: Georama;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 128.571% */
  text-transform: capitalize;
  padding: 10px;
  margin-bottom: 30px;
}

.donate {
  border-radius: 10px;
  background: #333;
  padding: 20px;
  height: 50%;
}

.donate p {
  color: #fff;
  font-family: Georama;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  /* 141.667% */
  margin-bottom: 20px;
}

.donate button {
  width: 285px;
  height: 30px;
  border-radius: 5px;
  background: #f01e29;
  border: none;
  width: 100%;
  color: #fff;
  font-family: Georama;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 128.571% */
  text-transform: capitalize;
}

.footerBottom {
  height: 95px;
  background: #262626;
  display: flex;
  justify-content: center;
}

.footerBottom p {
  color: #ccc;
  text-align: center;
  font-family: Georama;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 8px;
  /* 100% */
  display: flex;
  align-items: center;
}

.shareIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.shareIcons img {
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.link {
  margin-left: 10px;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}


/* responsive */

@media(max-width: 1024px) {
  .pagesTitle {
    font-size: 14px;
  }

  .pagesList {
    font-size: 12px;
  }

  .subMenuList {
    font-size: 12px;
  }

  .footerPolicy p {
    font-size: 12px;
    line-height: normal;
  }

  .donate p {
    font-size: 10px;
  }

  .footerBottom p {
    font-size: 12px;
  }

  .connectWithUs p {
    margin-bottom: 20px;
  }

  .shareIcons img {
    width: 20px;
    height: 15px;
  }
}

@media(max-width: 1100px) {
  .footerContent {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footerDiv {
    width: 48%;
  }

  .footerPages {
    width: 48%;
  }

  .footerPolicy {
    width: 48%;
  }

  .footerConnect {
    width: 48%;
  }

  .footer {
    height: fit-content;
  }
}

@media(max-width: 900px) {
  .footerPolicy p {
    line-height: 15px;
  }

  .subMenuItems {
    font-size: 10px;
  }


  .footerBottom p {
    margin-top: 20px;
  }

  .footerContent {
    padding: 10px 20px 10px;
  }
}

@media(max-width: 600px) {
  .footerDiv {
    width: 100%;
    height: fit-content;
  }

  .subMenuFlex {
    margin-bottom: 8px;
  }

  .footerPages {
    width: 100%;
    height: fit-content;
  }

  .footerPolicy {
    width: 100%;
    height: fit-content;
  }

  .footerConnect {
    width: 100%;
    height: fit-content;
  }

  .footerDiv img {
    width: 170px;
    height: 45px;
    cursor: pointer;
  }

  .truth {
    font-size: 10px;
    width: 100%;
  }

  .pagesTitle {
    width: 100%;
    font-size: 13px;
    margin-bottom: 10px;
    font-weight: 400;
  }

  .pagesList {
    margin-bottom: 8px;
    text-decoration: underline;
    font-weight: 600;
  }

  .subMenuList {
    margin-bottom: 8px;
    text-decoration: underline;
    font-weight: 600;
  }

  .connectWithUs p {
    font-weight: 400;
  }

  .shareIcons img {
    width: 30px;
    height: 30px;
  }

  .footerBottom p {
    font-size: 10px;
    line-height: normal;
  }

  .footerContent {
    padding: 10px 20px 10px;
  }
}

@media(max-width: 410px) {
  .shareIcons img {
    width: 20px;
    height: 20px;
  }
}