.navbarList {
    width: 100%;
    z-index: 1101;
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    flex-direction: column !important;
    /* align-items: center; */
    /* justify-content: start; */
    padding: 20px;
    overflow-y: scroll;
    font-family: Georama;
}

.closeIcon {
    width: 24px;
    height: 24px;
    margin-left: auto;
    cursor: pointer;
    margin-bottom: 1.5rem;
}

.linkFlex {
    display: flex;
    align-items: center;
    width: 100%;
}

.blackArrow {
    width: 8px;
    height: 4.5px;
    margin-left: auto;
}

.navbarList .accountItem {
    width: 100%;
    margin-top: 30px;
    /* margin-bottom: 1.75rem; */
    display: flex;
    align-items: center;
}

.navbarList .accountItem .navIcon {
    width: 1.188rem;
    margin-left: 0.75rem;
}

.checkedBySection {
    /* position: absolute; */
    /* bottom: 0; */
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-top: auto;
    margin-bottom: 20px;
}

.loginButton {
    width: auto;
    padding: 8px 16px;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    background: #F01E29;
    color: #FFF;
    font-family: Georama;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    text-transform: capitalize;
}

.donateButton {
    width: auto;
    height: 30px;
    padding: 7px 14px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    background: #F01E29;
    color: #FFF;
    font-family: Georama;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.donateButton img {
    width: 15px;
    height: 15px;

}

.circularDiv {

    width: 36px !important;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.profilePic {
    width: 100%;
    height: 100%;
    /* border-radius: 50%; */
    object-fit: cover;
}

.checkedByDetails {
    display: flex;
    flex-direction: column;
    margin: auto 0;
    /* row-gap: 2px; */
}

.checkedByDetails .checkedByName {
    color: #282828;
    font-family: Georama;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin: 0 !important;
    line-height: 13px;
}

.checkedByDetails .checkedByEmail {
    color: #828282;
    font-family: Georama;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 53px; */
    text-transform: lowercase;
    margin: 0 !important;
}

.navbarList .list .iconLinks {
    width: 100%;
    padding: 1rem 0;
    border-bottom: 1px solid #EBEBEB;
    display: flex;
    z-index: 65;
}

.navbarList .links .iconLinks .navIcon {
    height: 19px;
    margin-left: 0.75rem !important;
}

.navbarList .links .iconLinks .ant-select-selector {
    border: none;
}



.navbarList .list .listItem {
    width: 100%;
    padding: 10px 0px;
    font-family: Georama;
}

.navbarList .list .listSelect {
    width: 100%;
    padding: 10px 0;
    font-family: Georama;
}

.navbarList .list .listItem .navIcon {
    width: 1.188rem;
    margin-left: 0.75rem;
}

.navbarList .line {
    width: 100%;
    height: 0.063rem;
    background: #EBEBEB;
    border: none;
}

.logoutWrapper {
    position: fixed;
    bottom: 0px;
    /* padding-bottom: 37px; */
    background-color: #FFF;
    left: 0;
    width: 62.7vw;
    /* padding: 0 2rem 1.75rem 2rem; */
    padding: 1.75rem;
}

.logoutWrapper .logout {
    width: 100%;
    height: 2.5rem;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    font-size: 1rem;
    font-weight: 400;

}

@media (max-width: 757px) {
    .loginButton {
        font-size: 12px;
        padding: 4px 10px !important;
    }
}