.signinForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* row-gap: 0.8rem; */
    font-family: "Poppins";
    padding: 30px 18.5px;
}

.signinForm h1 {
    color: #282828;
    font-family: Georama;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    /* 104.167% */
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 10px;
}

.signInInput {
    padding: 7px !important;
    color: #6a6a6a !important;
    font-family: Georama !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    /* 125% */
}

.recaptchaContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 14px;
    font-family: Georama;
    margin-top: 20px;
    font-weight: 500;
}

.checking {
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-weight: 500;
}

.checkSuccess {
    display: flex;
    flex-direction: row;
    gap: 5px;
    color: darkgreen;
}

.checkFailed {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    color: #f01e29;
}

.tryAgain {
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;
    color: #6a6a6a;
}

.submitButton {
    padding: 8px 20px;
    /* height: 40px; */
    border: none;
    border-radius: 5px;
    background: #f01e29;
    color: #fff;
    font-family: Georama;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    /* 140% */
    text-transform: capitalize;
    margin-top: 10px;
    margin-bottom: 20px;

}

.submitButton:hover {
    color: none;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.orContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.orDiv {
    width: 45%;
    height: 3px;
    background: #d9d9d9;

}

.orContainer p {
    width: 7%;
    text-align: center;
    color: #6a6a6a;
    font-family: Georama;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 125% */
}

.otherSignIn {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 0px;
    border-radius: 5px;
    border: 2px solid #d9d9d9;
    margin-bottom: 20px;
    cursor: pointer;
}

.otherSignIn img {
    width: 20px;
    height: 20px;
}

.otherSignIn p {
    color: #282828;
    font-family: Georama;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 112.5% */
    text-transform: capitalize;
}

.signinForm p input {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.625rem;
}

.signinForm .inputField {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
}

.inputLabel {
    color: #282828;
    font-family: Georama;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 112.5% */
    text-transform: capitalize;
    position: relative;
}

.inputLabel::after {
    content: "*";
    position: absolute;
    /* Position the pseudo-element absolutely */
    top: 50%;
    /* Adjust the top position */
    transform: translateY(-50%);
    /* Center vertically */
    width: 20px;
    height: 20px;
    color: #f01e29;
    /* Other styles */
}

.signinOptions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.recoverPassword {
    color: #6a6a6a;
    text-align: right;
    font-family: Georama;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 141.667% */
    text-decoration-line: underline;
    cursor: pointer;
}

.submitButton {
    /* height: 3.188rem; */
    font-size: 1rem;
    font-weight: 700;
    border-radius: 3px;
    padding: 4px 10px;
}

.orText {
    width: 100% !important;
    text-align: center !important;
    justify-content: center;
}

.changeLoginType {
    border-radius: 0.375rem;
    border: none;
    padding: 0.563rem 1.25rem;
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;
    margin: 0;
    background-color: #fff;
    border: 1px solid #f2ad00;
    color: #f2ad00;
    cursor: pointer;
}

.signinForm .phoneInput {
    width: 100% !important;
    height: 3.375rem !important;
    font-size: 13px !important;
    height: 4.375rem !important;
}

.loginButton {
    height: 48px !important;
    margin-top: 40px;
}

.signinFooter {
    display: flex;
    font-family: "Poppins";
    /* border-top: 1px solid #dee2e6; */
    /* height: 3.563rem; */
    /* font-size: 0.875rem; */
    margin-top: 10px;
    justify-content: center;
    cursor: pointer;
}

.signinFooter p {
    color: #282828;
    font-family: Georama;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 141.667% */
    text-decoration-line: underline;
}

.signUpLink {
    color: #ef1e29;
    font-family: Georama;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    text-decoration-line: underline;
}

@media (max-width: 757px) {
    .recaptchaContent {
        font-size: 14px;
    }
}

@media(max-width: 480px) {
    .signinForm h1 {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .signinForm {
        padding: 0;
    }

    .inputLabel {
        font-size: 10px;
        font-weight: 600;
    }

    .signInInput {
        padding: 5px !important;
        font-size: 10px !important;
    }

    .signinOptions {
        margin-top: 20px;
    }

    .recoverPassword {
        font-size: 10px;
    }

    .checkBoxInput {
        font-size: 10px;
    }

    .recaptchaContent {
        font-size: 12px;
    }

    .submitButton {
        /* width: 54px; */
        /* height: 18px; */
        padding: 2px 8px;
        font-size: 12px;
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .orContainer p {
        font-size: 12px;
    }

    .orDiv {
        height: 1px;
    }

    .orContainer {
        margin-bottom: 20px;
    }

    .otherSignIn p {
        font-size: 12px;
    }

    .otherSignIn img {
        width: 12px;
        height: 12px;
    }

    .otherSignIn {
        height: 22px;
    }

    .signinFooter {
        margin-top: 0;
    }

    .signinFooter p {
        font-size: 12px;
    }

    .signUpLink {
        font-size: 12px;
    }

}

/* @media (min-width: 868px) {
  .signinForm .phoneInput {
    font-size: 16px !important;
  }
} */