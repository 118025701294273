.container {
    width: 100%;
    height: 100% !important;
    position: relative;

    background-color: #000;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    color: white !important;
    position: relative;
    overflow-y: scroll;
}

.navbar {
    height: 100px;
    z-index: 18;
    position: fixed;
    top: 0;
    padding: 0 40px;
    display: flex;
    align-items: center;
}

.navImage {
    height: 50px;
}

.contentImage {
    height: 50px;
}

.footer {
    position: fixed;
    bottom: 0;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
    margin-left: 24px;
}

.mainContent {
    position: absolute;
    width: 100%;
}

.contentWrapper {
    width: 60%;
    /* width: fit-content; */
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 176px;
    padding: 0 30px 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.comingSoon {
    margin-top: 16px;
    font-size: 50px;
    font-weight: 700;
    font-family: 'Montserrat';
    text-align: center;
}

.platform {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 32px;
    font-weight: 700;
    font-family: 'Montserrat';
    text-align: center;
}

.rowSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
}

.colSection {
    font-size: 50px;
    font-weight: 700;
    font-family: 'Montserrat';
    text-align: center;
    padding: 0 15px;
}

.colSection h4 {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Montserrat';
    text-align: center;
    margin-bottom: 8px;
}

.colSection p {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Montserrat';
    text-align: center;
    color: rgba(240, 248, 255, 0.384);
    margin-bottom: 16px;
}

.infoSection {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    padding: 0 15px;
}

.textInfo {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Montserrat';
    text-align: center;
}

.socialSection {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    gap: 5px;
}

.socialSection a {
    padding: 10px;
    border-radius: 10px;
}

.socialSection a:hover {
    background-color: #ffffff;
    color: #000000;
    text-decoration: none;
}

.buttonsSection {
    margin-top: 30px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
}

.loginButton {
    width: auto;
    padding: 8px 16px;
    margin-left: auto;
    border-radius: 5px;
    background: #F01E29;
    color: #FFF;
    font-family: Georama;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    text-transform: capitalize;
}

@media (max-width:834px) {
    .navImage {
        height: 30px;
    }

    .contentWrapper {
        width: 61%;
        /* width: fit-content; */
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 76px;
        padding-top: 54px;
    }

    .colSection {
        padding: 0 15px;
    }

    .colSection h4 {
        font-size: 16px;
    }

    .colSection p {
        font-size: 12px;
    }

    .textInfo {
        font-size: 12px;
    }

    .footer {
        font-size: 12px;
    }
}

@media (max-width:630px) {
    .contentWrapper {
        width: 84%;
    }
}