.signUpModal {
  width: 679px !important;
  padding: 50px 10px !important;
}

.modalClose {
  width: 20px;
  height: 20px;
}

@media(max-width: 700px) {
  .signUpModal {
    width: 95vw !important;
  }

  .modalClose {
    width: 10px;
    height: 10px;
  }
}