.signInModal {
    width: 679px !important;
    padding: 50px 10px !important;
}

.modalIcon {
    width: 20px;
    height: 20px;
}

.bottomNote {
    color: #282828;
    font-family: Georama;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    text-align: center;
    margin-top: -10px;
    margin-bottom: 20px;
}

.bottomNote span {
    color: #ef1e29;
    cursor: pointer;
}

@media(max-width: 700px) {
    .signInModal {
        width: 84vw;
    }
}

@media(max-width: 480px) {
    .modalIcon {
        width: 10px;
        height: 10px;
    }

    .bottomNote {
        font-size: 12px;
        margin-top: 15px;
    }
}